import React, { useState, createContext } from "react";

const EditingMessageContext = createContext();

const EditingMessageProvider = ({ children }) => {
	const [editingMessage, setEditingMessage] = useState(null);

	return (
		<EditingMessageContext.Provider value={{ editingMessage, setEditingMessage }}>
			{children}
		</EditingMessageContext.Provider>
	);
};

export { EditingMessageContext, EditingMessageProvider };

import { Button } from "@material-ui/core";
import React, { useRef, useState, useEffect } from "react";
import api from "../../services/api"; // Certifique-se de que o caminho está correto
import toastError from "../../errors/toastError";

const LS_NAME = "audioMessageRate";

function Audio({ url, fileName, messageId, initialTranscription = "", internal = false }) {
  const audioRef = useRef(null);
  const [audioRate, setAudioRate] = useState(parseFloat(localStorage.getItem(LS_NAME) || "1"));
  const [showButtonRate, setShowButtonRate] = useState(false);
  const [transcribedText, setTranscribedText] = useState(initialTranscription);
  // Apenas exibe o botão se a mensagem NÃO for interna e ainda não tiver transcrição
  const [showTranscriptionButton, setShowTranscriptionButton] = useState(!internal && !initialTranscription);

  useEffect(() => {
    audioRef.current.playbackRate = audioRate;
    localStorage.setItem(LS_NAME, audioRate);
  }, [audioRate]);

  useEffect(() => {
    audioRef.current.onplaying = () => {
      setShowButtonRate(true);
    };
    audioRef.current.onpause = () => {
      setShowButtonRate(false);
    };
    audioRef.current.onended = () => {
      setShowButtonRate(false);
    };
  }, []);

  const toogleRate = () => {
    let newRate;

    switch (audioRate) {
      case 0.5:
        newRate = 1;
        break;
      case 1:
        newRate = 1.5;
        break;
      case 1.5:
        newRate = 2;
        break;
      case 2:
        newRate = 0.5;
        break;
      default:
        newRate = 1;
        break;
    }

    setAudioRate(newRate);
  };

  const handleTranscription = async () => {
    try {
      const { data } = await api.get(`/messages/transcribeAudio/${fileName}/${messageId}`);
      setTranscribedText(data); // Atualiza o texto da transcrição
      setShowTranscriptionButton(false); // Oculta o botão após transcrição bem-sucedida
    } catch (error) {
      toastError(error);
    }
  };

  return (
    <div style={{ display: "flex", flexDirection: "column", alignItems: "center", gap: "10px" }}>
      <audio ref={audioRef} controls style={{ width: "100%" }}>
        <source src={url} type="audio/ogg" />
      </audio>
      
      {showButtonRate && (
        <Button style={{ alignSelf: "center" }} onClick={toogleRate}>
          {audioRate}x
        </Button>
      )}
      
      {/* O botão de transcrição só aparece se a mensagem NÃO for interna */}
      {!internal && showTranscriptionButton ? (
        <Button
          style={{ alignSelf: "center" }}
          variant="contained"
          color="primary"
          onClick={handleTranscription}
        >
          Transcrever Áudio
        </Button>
      ) : (
        transcribedText && (
          <div
            style={{
              marginTop: "10px",
              padding: "10px",
              backgroundColor: "#f0f0f0",
              textAlign: "center",
              width: "100%",
            }}
          >
            <strong>Transcrição:</strong>
            <p>{transcribedText}</p>
          </div>
        )
      )}
    </div>
  );
}

export default Audio;

import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";

const ContactLists = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const tokenEmpresa = queryParams.get("tokenEmpresa"); // Pega o par?metro tokenEmpresa da URL

  useEffect(() => {
    const userAgent = navigator.userAgent.toLowerCase();
    const isChrome = userAgent.includes("chrome") && !userAgent.includes("edge");

    if (isChrome) {
      // Abre em outra aba se for Chrome
      const url = `https://sistemashep.com.br/lista_contatos/?tokenEmpresa=${tokenEmpresa}`;
      window.open(url, "_blank");
    }
  }, [tokenEmpresa]);

  return (
    <div style={{ width: "100%", height: "100%" }}>
      {/* Apenas renderiza o iframe se n?o for Chrome */}
      {!navigator.userAgent.toLowerCase().includes("chrome") && (
        <iframe
          src={`https://sistemashep.com.br/lista_contatos/?tokenEmpresa=${tokenEmpresa}`}
          style={{
            width: "100%",
            height: "calc(100vh - 64px)", // Altura ajustada para layout
            border: "none",
          }}
          title="Listas de Contatos"
        ></iframe>
      )}
    </div>
  );
};

export default ContactLists;
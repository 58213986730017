import React, { useContext, useState } from "react";
import { useHistory } from "react-router-dom";
import { makeStyles, createTheme, ThemeProvider } from "@material-ui/core/styles";
import { IconButton, Tooltip } from "@material-ui/core";
import { MoreVert, Replay, CheckCircle as CheckCircleIcon, UndoRounded as UndoRoundedIcon, Star as StarIcon, StarBorder as StarBorderIcon } from "@material-ui/icons";
import { toast } from "react-toastify";
import { green } from "@material-ui/core/colors";

import { i18n } from "../../translate/i18n";
import api from "../../services/api";
import TicketOptionsMenu from "../TicketOptionsMenu";
import ButtonWithSpinner from "../ButtonWithSpinner";
import toastError from "../../errors/toastError";
import { AuthContext } from "../../context/Auth/AuthContext";
import { TicketsContext } from "../../context/Tickets/TicketsContext";

const useStyles = makeStyles((theme) => ({
  actionButtons: {
    marginRight: 6,
    flex: "none",
    alignSelf: "center",
    marginLeft: "auto",
    "& > *": {
      margin: theme.spacing(0.5),
    },
  },
}));

const TicketActionButtonsCustom = ({ ticket }) => {
  const classes = useStyles();
  const history = useHistory();
  const [anchorEl, setAnchorEl] = useState(null);
  const [loading, setLoading] = useState(false);
  const ticketOptionsMenuOpen = Boolean(anchorEl);
  const { user } = useContext(AuthContext);
  const { setCurrentTicket, refreshTickets } = useContext(TicketsContext);

  const customTheme = createTheme({
    palette: {
      primary: green,
    },
  });

  const handleOpenTicketOptionsMenu = (e) => {
    setAnchorEl(e.currentTarget);
  };

  const handleCloseTicketOptionsMenu = () => {
    setAnchorEl(null);
  };

  const handleUpdateTicketStatus = async (e, status, userId) => {
    setLoading(true);
    try {
      //console.log("📌 Enviando userId para o backend:", userId); // <-- Debug

      await api.put(`/tickets/${ticket.id}`, {
        status,
        userId: userId || null,
        loggedUserId: user.id, // ✅ Passando o usuário autenticado
        useIntegration: status === "closed" ? false : ticket.useIntegration,
        promptId: status === "closed" ? false : ticket.promptId,
        integrationId: status === "closed" ? false : ticket.integrationId,
      });
      

      setLoading(false);
      if (status === "open") {
        setCurrentTicket({ ...ticket, code: "#open" });
      } else {
        setCurrentTicket({ id: null, code: null });
        history.push("/tickets");
      }
    } catch (err) {
      setLoading(false);
      toastError(err);
    }
  };

  const handleToggleFixTicket = async () => {
	setLoading(true);
	try {
	  await api.put(`/tickets/${ticket.id}/fix`, { silent: true });  // Envia a flag `silent: true` para o backend
	  toast.success(ticket.is_fixed ? "Ticket desfixado" : "Ticket fixado");
	  
	  history.push("/tickets");  // Redireciona para a lista de tickets
	} catch (err) {
	  toastError(err);
	} finally {
	  setLoading(false);
	}
  };
  

  return (
    <div className={classes.actionButtons}>
      {ticket.status === "closed" && (
        <ButtonWithSpinner
          loading={loading}
          startIcon={<Replay />}
          size="small"
          onClick={(e) => handleUpdateTicketStatus(e, "open", user?.id)}
        >
          {i18n.t("messagesList.header.buttons.reopen")}
        </ButtonWithSpinner>
      )}
      {ticket.status === "open" && (
        <>
          <Tooltip title={i18n.t("messagesList.header.buttons.return")}>
            <IconButton onClick={(e) => handleUpdateTicketStatus(e, "pending", user.id)}>
              <UndoRoundedIcon />
            </IconButton>
          </Tooltip>
          <ThemeProvider theme={customTheme}>
            <Tooltip title={i18n.t("messagesList.header.buttons.resolve")}>
              <IconButton onClick={(e) => handleUpdateTicketStatus(e, "closed", user?.id)} color="primary">
                <CheckCircleIcon />
              </IconButton>
            </Tooltip>
          </ThemeProvider>
          <Tooltip title={ticket.is_fixed ? "Desfixar Ticket" : "Fixar Ticket"}>
            <IconButton
              onClick={handleToggleFixTicket}
              disabled={loading}
            >
              {ticket.is_fixed ? <StarIcon style={{ color: "gold" }} /> : <StarBorderIcon />}
            </IconButton>
          </Tooltip>
          <IconButton onClick={handleOpenTicketOptionsMenu}>
            <MoreVert />
          </IconButton>
          <TicketOptionsMenu
            ticket={ticket}
            anchorEl={anchorEl}
            menuOpen={ticketOptionsMenuOpen}
            handleClose={handleCloseTicketOptionsMenu}
          />
        </>
      )}
      {ticket.status === "pending" && (
        <ButtonWithSpinner
          loading={loading}
          size="small"
          variant="contained"
          color="primary"
          onClick={(e) => handleUpdateTicketStatus(e, "open", user?.id)}
        >
          {i18n.t("messagesList.header.buttons.accept")}
        </ButtonWithSpinner>
      )}
    </div>
  );
};

export default TicketActionButtonsCustom;
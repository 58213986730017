import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";

const Suporte = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const tokenEmpresa = queryParams.get("tokenEmpresa"); // Pega o parâmetro tokenEmpresa da URL

  useEffect(() => {
    const userAgent = navigator.userAgent.toLowerCase();
    const isChrome = userAgent.includes("chrome") && !userAgent.includes("edge");

    if (isChrome) {
      // Abre em outra aba se for Chrome
      const url = `https://sistemashep.com.br/chamados/?tokenEmpresa=${tokenEmpresa}`;
      window.open(url, "_blank");
    }
  }, [tokenEmpresa]);

  return (
    <div style={{ width: "100%", height: "100%" }}>
      {/* Apenas renderiza o iframe se não for Chrome */}
      {!navigator.userAgent.toLowerCase().includes("chrome") && (
        <iframe
          src={`https://sistemashep.com.br/chamados/?tokenEmpresa=${tokenEmpresa}`}
          style={{
            width: "100%",
            height: "calc(100vh - 64px)", // Ajuste para considerar menus ou cabeçalhos
            border: "none",
          }}
          title="Suporte"
        ></iframe>
      )}
    </div>
  );
};

export default Suporte;
